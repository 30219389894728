import { Box, Center, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const Cta = () => {
  return (
    <Box mt={'0px'} mb={{ base: '0px', md: '160px' }} textAlign={'center'}>
      <Center
        margin={'0 auto'}
        bgGradient="linear(to-r, #FE6703, #EE0A77)"
        maxWidth={'1370px'}
        borderRadius={{ base: '0px', md: '20px' }}
        height={{ base: '100%', sm: '400px' }}
        color="white"
        py={{ base: '40px', md: '0px' }}
      >
        <Flex flexDirection={'column'} gap={'5px'} alignItems={'center'}>
          <Text fontSize="4xl" fontWeight={'800'}>
            More Personalization?
          </Text>
          <Text fontSize="4xl" fontWeight={'800'}>
            More Conversions!
          </Text>

          <Text fontSize="xl" fontWeight={'600'} mt={'20px'}>
            Are you ready to unlock a new world of possibilities?
          </Text>
        </Flex>
      </Center>
    </Box>
  );
};

export default Cta;
