import React from 'react';
import {
  Show,
  Hide,
  chakra,
  Box,
  Button,
  Stack,
  Text,
  Image,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useScroll, useTransform } from 'framer-motion';
import { scroller } from 'react-scroll';
import { useMediaQuery } from '@chakra-ui/react';
import bgVideos from '../images/mockup.gif';
// import bgVideosWebm from '../images/mockup.webm';
import fallbackMock from '../images/pika-2022-07-28T15_51_10.654Z.png';

const Hero = () => {
  const { scrollYProgress } = useScroll();
  const [isLessThan700] = useMediaQuery('(max-width: 700px)');
  const scale = useTransform(
    scrollYProgress,
    [0, isLessThan700 ? 0.4 : 0.6],
    [isLessThan700 ? 1 : 0.8, isLessThan700 ? 1.2 : 1.2]
  );
  const position = useTransform(
    scrollYProgress,
    [0, 0.3],
    [0, isLessThan700 ? -70 : -300]
  );
  return (
    <Box
      px={8}
      py={{ base: 4, sm: '0px' }}
      paddingTop={{ base: 12, sm: '80px' }}
      mx="auto"
    >
      <Box
        as={motion.div}
        transition={{ type: 'spring', damping: 10, stiffness: 100 }}
        initial={{ opacity: 0, y: 85 }}
        animate={{ opacity: 1, y: 0 }}
        w={{ base: 'full', md: 11 / 12, xl: 8 / 12 }}
        mx="auto"
        textAlign={{ base: 'left', md: 'center' }}
      >
        <chakra.h1
          mb={6}
          fontSize={{ base: '4xl', md: '6xl' }}
          fontWeight="700"
          lineHeight="120%"
          letterSpacing={{ base: 'normal', md: 'tight' }}
          color="gray.900"
          _dark={{ color: 'gray.100' }}
        >
          {/* The easiest way to create{' '} */}
          {/* Power your email marketing using{' '} */}
          Power your email marketing using{' '}
          <Text
            display={{ base: 'block', lg: 'inline' }}
            w="full"
            bgClip="text"
            bgGradient="linear(to-r, #FF6A00, #EE0979)"
            fontWeight="700"
          >
            personalized
          </Text>{' '}
          graphics.
        </chakra.h1>
        <chakra.p
          px={{ base: 0, md: 40 }}
          mb={6}
          fontSize={{ base: 'lg', md: 'xl' }}
          color="gray.600"
          _dark={{ color: 'gray.300' }}
        >
          Marketing power tool for teams where they can easily create, design,
          and manage personalized content across marketing channels.
        </chakra.p>
        <Box
          transition={{ type: 'spring', damping: 10, stiffness: 100 }}
          initial={{ opacity: 0, y: 85 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <Stack
            direction={{ base: 'column', sm: 'row' }}
            mb={{ base: 4, md: 8 }}
            spacing={2}
            justifyContent={{ sm: 'left', md: 'center' }}
          >
            <Button
              as="a"
              variant="solid"
              bgColor={'brand.900'}
              colorScheme="white"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              w={{ base: 'full', sm: 'auto' }}
              mb={{ base: 2, sm: 0 }}
              size="lg"
              fontSize={'md'}
              cursor="pointer"
              _dark={{ bgColor: '#ffffff' }}
              onClick={() =>
                scroller.scrollTo('signUpSection', { smooth: true })
              }
            >
              Sign Up For Beta ⚡️
            </Button>
            {/* <Button
              as="a"
              colorScheme="gray"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              w={{ base: 'full', sm: 'auto' }}
              mb={{ base: 2, sm: 0 }}
              size="lg"
              fontSize={'md'}
              cursor="pointer"
            >
              Learn More
            </Button> */}
          </Stack>
        </Box>
      </Box>

      <Box
        w={{ base: 'full', md: 10 / 12 }}
        mx="auto"
        mt={20}
        textAlign="center"
        as={motion.div}
        initial={{ scale: isLessThan700 ? 1.0 : 0.65 }}
        style={{
          position: 'relative',
          top: position,
          scale: scale,
        }}
      >
        <Image
          style={{
            borderRadius: '20px',
            boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
          }}
          src={bgVideos}
          alt="mockup"
        />
        {/* <video
          style={{
            borderRadius: '20px',
            boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
          }}
          loop
          autoPlay
          playsInline
          preload="metadata"
        >
          {/* <video
          style={{
            borderRadius: '20px',
            boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
          }}
          muted
          playsInline
          loop
          autoPlay
          type="video/mp4"
          codecs="avc1.4d002a, mp4a.40.2"
          preload="none"
        > 
          <source src={bgVideosWebm} type="video/webm" codecs="vp8, vorbis" />
          <source src={bgVideos} type="video/mp4" codecs="avc1.4d002a" />
          <Image width={{ base: 'full' }} src={fallbackMock} alt="mockup" />
          Your browser does not support the video tag.
        </video> */}
      </Box>
    </Box>
  );
};

export default Hero;
