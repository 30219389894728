import React, { useState } from 'react';
import {
  chakra,
  Box,
  useColorModeValue,
  Stack,
  Image,
  Text,
  calc,
  Flex,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useMediaQuery } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import groovyWalkAnimation from './touch.json';
const CustomText = ({ onClick, value, index, activeIndex }) => {
  const colorMode1 = useColorModeValue('#000', '#fff');
  const colorMode2 = useColorModeValue(
    'rgba(51, 51, 51, 0.52)',
    'rgb(255, 255, 255,0.25)'
  );

  return (
    <Text
      style={{
        color: activeIndex === index ? colorMode1 : colorMode2,
      }}
      fontSize={{ base: '20px', md: '24px' }}
      cursor={'pointer'}
      onClick={onClick}
    >
      {value}
    </Text>
  );
};

const Graphics = ({ isLessThan700, graphicsType, firstName, offer }) => {
  const graphicsDict = [
    {
      src: 'https://datavids.s3.eu-central-1.amazonaws.com/assets/1.webp',
      fcolor: '#212F4A',
      textDecoration: 'none',
      fontWeight: '900',
      width: 'auto',
      fx: isLessThan700 ? '83px' : '113px',
      fy: isLessThan700 ? '82px' : '110px',
      ox: '0px',
      oy: '0px',
      fontf: '',
      fonto: '',
      fontoColor: '#fff',
      fontoSize: '2px',
      fontfSize: isLessThan700 ? '27px' : '40px',
    },
    {
      src: 'https://datavids.s3.eu-central-1.amazonaws.com/assets/2.webp',
      fcolor: '#000',
      textDecoration: 'none',
      width: '100%',
      fx: '0px',
      fy: isLessThan700 ? '20px' : '30px',
      ox: isLessThan700 ? '140px' : '192px',
      oy: isLessThan700 ? '255px' : '352px',
      fontf: '',
      fonto: '',
      fontoColor: '#fff',
      fontoSize: isLessThan700 ? '11px' : '16px',
      fontfSize: isLessThan700 ? '30px' : '32px',
    },
    {
      src: 'https://datavids.s3.eu-central-1.amazonaws.com/assets/3.webp',
      fcolor: '#fff',
      textDecoration: 'underline #FEEB41',
      width: '100%',
      fx: '0px',
      fy: isLessThan700 ? '60px' : '89px',
      ox: isLessThan700 ? '165px' : '232px',
      oy: isLessThan700 ? '204px' : '282px',
      fontf: '',
      fonto: '',
      fontoColor: '#fff',
      fontoSize: isLessThan700 ? '24px' : '30px',
      fontfSize: '40px',
    },
    {
      src: 'https://datavids.s3.eu-central-1.amazonaws.com/assets/4.webp',
      fcolor: '#f47378',
      width: '100%',
      textDecoration: 'none',
      fx: '0px',
      fy: isLessThan700 ? '10px' : '30px',
      ox: isLessThan700 ? '127px' : '175px',
      oy: isLessThan700 ? '158px' : '219px',
      fontf: '',
      fonto: '',
      fontoColor: '#fff',
      fontoSize: isLessThan700 ? '22px' : '30px',
      fontfSize: '40px',
    },
  ];
  return (
    <Box
      position={'relative'}
      w={{ base: '350px', md: 'auto' }}
      m={{ base: '0 auto' }}
    >
      <Image
        w={{ base: '350px', md: 'auto' }}
        shadow={'dark-lg'}
        src={graphicsDict[graphicsType].src}
      ></Image>
      <Text
        style={{
          position: 'absolute',
          left: graphicsDict[graphicsType].fx,
          top: graphicsDict[graphicsType].fy,
          width: graphicsDict[graphicsType].width,
          textAlign: 'center',
          textDecoration: graphicsDict[graphicsType].textDecoration,
        }}
        fontSize={graphicsDict[graphicsType].fontfSize}
        fontWeight={'700'}
        color={graphicsDict[graphicsType].fcolor}
      >
        {firstName}
      </Text>
      <Text
        style={{
          position: 'absolute',
          left: graphicsDict[graphicsType].ox,
          top: graphicsDict[graphicsType].oy,
        }}
        fontSize={graphicsDict[graphicsType].fontoSize}
        fontWeight={'700'}
        color={graphicsDict[graphicsType].fontoColor}
      >
        {offer}
      </Text>
    </Box>
  );
};

const CreativeExample = () => {
  const [isLessThan700] = useMediaQuery('(max-width: 700px)');
  const [isShown, setisShown] = useState(true);
  const [firstName, setFirstName] = useState('Danielle');
  const [creativeType, setCreativeType] = useState('Purchased');
  const [offer, setOffer] = useState('25%');
  const [isFirstNameActive, setIsFirstNameActive] = useState(0);
  const [isCreativeTypeActive, setIsCreativeTypeActive] = useState(2);
  const [isOfferActive, setIsOfferActive] = useState(1);
  return (
    <Box
      px={{ base: '0', md: '8' }}
      py={{ base: 20, sm: 5 }}
      mx="auto"
      mb={{ base: '0px', md: '70px' }}
    >
      <Box textAlign={'center'} px={6} mb={{ base: '0px', md: '70px' }}>
        {' '}
        <chakra.h1
          textAlign={{ base: 'left', md: 'center' }}
          mb={6}
          fontSize={{ base: '4xl', md: '6xl' }}
          fontWeight="bold"
          lineHeight="120%"
          letterSpacing={{ base: 'normal', md: 'tight' }}
          color="gray.900"
          _dark={{ color: 'gray.100' }}
        >
          Personalized graphics for each contact
        </chakra.h1>
        <chakra.p
          textAlign={{ base: 'left', md: 'center' }}
          px={{ base: 0, sm: 1 }}
          margin={{ sm: '0 auto' }}
          maxW={{ base: 'none', sm: '800px' }}
          mb={6}
          fontSize={{ base: 'lg', md: 'xl' }}
          color="gray.600"
          _dark={{ color: 'gray.300' }}
        >
          Imagine a world where you can easily generate personalized graphics{' '}
          <b>uniquely</b> for your customers. Typing manually? Hell no.
        </chakra.p>
      </Box>
      <Flex
        px={{ base: 5, sm: 0 }}
        flexDirection={{
          base: 'column',
          md: 'row',
        }}
        gap={8}
        spacing="24px"
        maxW={'1000px'}
        mx="auto"
      >
        <Flex
          direction={'column'}
          p={{ base: '2', md: '8' }}
          w={{ base: '100%', md: '50%' }}
          h={{ base: 'auto', md: '500px' }}
        >
          <Text>Choose Name:</Text>
          <Flex
            mb={'30px'}
            columnGap={'30px'}
            flexWrap={'wrap'}
            position={'relative'}
          >
            <Box
              style={{
                position: 'absolute',
                top: '-12px',
                left: '158px',
                zIndex: '-1',
              }}
            >
              <Lottie
                style={{
                  display: isShown ? 'block' : 'none',
                  width: '50px',
                  top: '20px',
                }}
                animationData={groovyWalkAnimation}
                loop={true}
              />
            </Box>
            <CustomText
              index={0}
              value={'Danielle'}
              activeIndex={isFirstNameActive}
              onClick={() => {
                setisShown(false);
                setIsFirstNameActive(0);
                setFirstName('Danielle');
              }}
            />
            <CustomText
              index={1}
              value={'Michael'}
              activeIndex={isFirstNameActive}
              onClick={() => {
                setisShown(false);
                setIsFirstNameActive(1);
                setFirstName('Michael');
              }}
            />
            <CustomText
              index={2}
              value={'Danny'}
              activeIndex={isFirstNameActive}
              onClick={() => {
                setIsFirstNameActive(2);
                setFirstName('Danny');
                setisShown(false);
              }}
            />
            <CustomText
              index={3}
              value={'Mike'}
              activeIndex={isFirstNameActive}
              onClick={() => {
                setIsFirstNameActive(3);
                setisShown(false);
                setFirstName('Mike');
              }}
            />
            <CustomText
              index={4}
              value={'John'}
              activeIndex={isFirstNameActive}
              onClick={() => {
                setIsFirstNameActive(4);
                setFirstName('John');
                setisShown(false);
              }}
            />
            <CustomText
              index={5}
              value={'Christopher'}
              activeIndex={isFirstNameActive}
              onClick={() => {
                setIsFirstNameActive(5);
                setFirstName('Christopher');
              }}
            />
          </Flex>
          <Text>Choose Email Type:</Text>
          <Flex
            mb={'30px'}
            fontSize={'30px'}
            columnGap={'30px'}
            flexWrap={'wrap'}
          >
            <CustomText
              index={0}
              value={'Wellcome'}
              activeIndex={isCreativeTypeActive}
              onClick={() => {
                setIsCreativeTypeActive(0);
                setCreativeType('Wellcome');
                setisShown(false);
              }}
            />
            <CustomText
              index={1}
              value={'Purchased'}
              activeIndex={isCreativeTypeActive}
              onClick={() => {
                setIsCreativeTypeActive(1);
                setCreativeType('Purchased');
              }}
            />
            <CustomText
              index={2}
              value={'Abandoned Cart'}
              activeIndex={isCreativeTypeActive}
              onClick={() => {
                setIsCreativeTypeActive(2);
                setCreativeType('Abandoned Cart');
              }}
            />
            <CustomText
              index={3}
              value={'Birthday'}
              activeIndex={isCreativeTypeActive}
              onClick={() => {
                setIsCreativeTypeActive(3);
                setCreativeType('Birthday');
                setisShown(false);
              }}
            />
          </Flex>
          <Text>Choose Offer:</Text>
          <Flex
            mb={'0px'}
            fontSize={'30px'}
            columnGap={'30px'}
            flexWrap={'wrap'}
          >
            <CustomText
              index={0}
              value={'10%'}
              activeIndex={isOfferActive}
              onClick={() => {
                setIsOfferActive(0);
                setOffer('10%');
              }}
            />
            <CustomText
              index={1}
              value={'25%'}
              activeIndex={isOfferActive}
              onClick={() => {
                setIsOfferActive(1);
                setOffer('25%');
              }}
            />
            <CustomText
              index={2}
              value={'50%'}
              activeIndex={isOfferActive}
              onClick={() => {
                setIsOfferActive(2);
                setOffer('50%');
                setisShown(false);
              }}
            />
          </Flex>
        </Flex>
        <Box
          w={isLessThan700 ? '100%' : '50%'}
          h={{ base: 'auto', md: '450px' }}
        >
          <Graphics
            isLessThan700={isLessThan700}
            firstName={firstName}
            offer={offer}
            graphicsType={isCreativeTypeActive}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default CreativeExample;
