import {
  chakra,
  Box,
  Button,
  Stack,
  Image,
  Text,
  calc,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import React from 'react';

const Intergrations = () => {
  return (
    <Box>
      <Box
        as={motion.div}
        transition={{ type: 'spring', damping: 10, stiffness: 100 }}
        initial={{ opacity: 0, y: 85 }}
        animate={{ opacity: 1, y: 0 }}
        px={6}
        w={{ base: 'full', md: 11 / 12, xl: 8 / 12 }}
        mx="auto"
        textAlign={{ base: 'left', md: 'center' }}
      >
        <chakra.h1
          mb={6}
          fontSize={{ base: '4xl', md: '6xl' }}
          fontWeight="bold"
          lineHeight="120%"
          letterSpacing={{ base: 'normal', md: 'tight' }}
          color="gray.900"
          _dark={{ color: 'gray.100' }}
        >
          Integrate the most powerfull{' '}
          <Text
            display={{ base: 'block', lg: 'inline' }}
            w="full"
            bgClip="text"
            bgGradient="linear(to-r, #FF6A00, #EE0979)"
            fontWeight="extrabold"
          >
            ESPs
          </Text>
        </chakra.h1>
        <chakra.p
          px={{ base: 0, lg: 64 }}
          mb={6}
          fontSize={{ base: 'lg', md: 'xl' }}
          color="gray.600"
          _dark={{ color: 'gray.300' }}
        >
          We seamlessly integrate your <strong>email service provider</strong>{' '}
          so you can focus on what matter.
        </chakra.p>
      </Box>
      <Box
        marginTop={{ base: '0px', md: '50px' }}
        marginBottom={'20px'}
        className="brand-wheel"
      >
        <Box className="brand-slide delay">
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7ddaf7ba83cf0ba5047_Group%201000002515.png"
              alt=""
              className="image-10"
            />
          </Box>
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7dda651894a438b65c0_Group%201000002518.png"
              alt=""
              className="image-10"
            />
          </Box>
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7ddbca7087d42513389_Group%201000002520.png"
              width="73"
              alt=""
              className="image-10"
            />
          </Box>
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7dd0cb06dd0a736637e_Group%201000002514.png"
              loading="lazy"
              width="136"
              alt=""
              className="image-10"
            />
          </Box>
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7dd5b11fa3912df82ce_Group%201000002516.png"
              width="137"
              alt=""
              className="image-10"
            />
          </Box>
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7dd01149a722ca61381_Group%201000002517.png"
              alt=""
              className="image-10"
            />
          </Box>
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7dd25f6396ef24ca557_Group%201000002521.png"
              alt=""
              className="image-10"
            />
          </Box>
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7dd57f1004a48609404_Group%201000002513.png"
              alt=""
              className="image-10"
            />
          </Box>
        </Box>
        <Box className="brand-slide">
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7ddaf7ba83cf0ba5047_Group%201000002515.png"
              alt=""
              className="image-10"
            />
          </Box>
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7dda651894a438b65c0_Group%201000002518.png"
              alt=""
              className="image-10"
            />
          </Box>
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7ddbca7087d42513389_Group%201000002520.png"
              width="73"
              alt=""
              className="image-10"
            />
          </Box>
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7dd0cb06dd0a736637e_Group%201000002514.png"
              loading="lazy"
              width="136"
              alt=""
              className="image-10"
            />
          </Box>
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7dd5b11fa3912df82ce_Group%201000002516.png"
              width="137"
              alt=""
              className="image-10"
            />
          </Box>
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7dd01149a722ca61381_Group%201000002517.png"
              alt=""
              className="image-10"
            />
          </Box>
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7dd25f6396ef24ca557_Group%201000002521.png"
              alt=""
              className="image-10"
            />
          </Box>
          <Box className="logo-div" w={{ base: '150px', md: 'auto' }}>
            <Image
              src="https://assets.website-files.com/6166a46c7fab1b2584c99157/62dfd7dd57f1004a48609404_Group%201000002513.png"
              alt=""
              className="image-10"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Intergrations;
