import {
  Text,
  Box,
  Heading,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';

export default function Terms() {
  return (
    <Box
      p={4}
      style={{
        maxWidth: '990px',
        margin: '0 auto',
        marginTop: '20px',
        marginBottom: '80px',
      }}
    >
      <Heading mb={5} as={'h1'}>
        Web Site Terms and Conditions of Use
      </Heading>
      <OrderedList role="list" spacing={5}>
        <ListItem>
          <Heading as={'h3'} fontSize={'2xl'}>
            Terms
          </Heading>
        </ListItem>
        <Text>
          By accessing this web site, you are agreeing to be bound by these web
          site Terms and Conditions of Use, applicable laws and regulations and
          their compliance. If you disagree with any of the stated terms and
          conditions, you are prohibited from using or accessing this site. The
          materials contained in this site are secured by relevant copyright and
          trade mark law.
        </Text>
        <ListItem>
          <Heading as={'h3'} fontSize={'2xl'}>
            Use License
          </Heading>
        </ListItem>
        <UnorderedList role="list">
          <ListItem>
            Permission is allowed to temporarily download one duplicate of the
            materials (data or programming) on DataVids&#x27;s site for
            individual and non-business use only. This is the just a permit of
            license and not an exchange of title, and under this permit you may
            not:
          </ListItem>
          <ListItem>modify or copy the materials;</ListItem>
          <ListItem>
            use the materials for any commercial use , or for any public
            presentation (business or non-business);
          </ListItem>
          <ListItem>
            attempt to decompile or rebuild any product or material contained on
            DataVids&#x27;s site;
          </ListItem>
          <ListItem>
            remove any copyright or other restrictive documentations from the
            materials; or
          </ListItem>
          <ListItem>
            transfer the materials to someone else or even &quot;mirror&quot;
            the materials on other server.
          </ListItem>
          <ListItem>
            This permit might consequently be terminated if you disregard any of
            these confinements and may be ended by DataVids whenever deemed.
            After permit termination or when your viewing permit is terminated,
            you must destroy any downloaded materials in your ownership whether
            in electronic or printed form.
          </ListItem>
        </UnorderedList>
        <ListItem>
          <Heading as={'h3'} fontSize={'2xl'}>
            Disclaimer
          </Heading>
        </ListItem>
        <UnorderedList role="list">
          <ListItem>
            The materials on DataVids&#x27;s site are given &quot;as is&quot;.
            DataVids makes no guarantees, communicated or suggested, and thus
            renounces and nullifies every single other warranties, including
            without impediment, inferred guarantees or states of
            merchantability, fitness for a specific reason, or non-encroachment
            of licensed property or other infringement of rights. Further,
            DataVids does not warrant or make any representations concerning the
            precision, likely results, or unwavering quality of the utilization
            of the materials on its Internet site or generally identifying with
            such materials or on any destinations connected to this website.
          </ListItem>
        </UnorderedList>
        <ListItem>
          <Heading as={'h3'} fontSize={'2xl'}>
            Constraints
          </Heading>
        </ListItem>
        <Text>
          In no occasion should DataVids or its suppliers subject for any harms
          (counting, without constraint, harms for loss of information or
          benefit, or because of business interference,) emerging out of the
          utilization or powerlessness to utilize the materials on
          DataVids&#x27;s Internet webpage, regardless of the possibility that
          DataVids or a DataVids approved agent has been told orally or in
          written of the likelihood of such harm. Since a few purviews
          don&#x27;t permit constraints on inferred guarantees, or impediments
          of obligation for weighty or coincidental harms, these confinements
          may not make a difference to you.
        </Text>
        <ListItem>
          <Heading as={'h3'} fontSize={'2xl'}>
            Amendments and Errata
          </Heading>
        </ListItem>
        <Text>
          The materials showing up on DataVids&#x27;s site could incorporate
          typographical, or photographic mistakes. DataVids does not warrant
          that any of the materials on its site are exact, finished, or current.
          DataVids may roll out improvements to the materials contained on its
          site whenever without notification. DataVids does not, then again,
          make any dedication to update the materials.
        </Text>
        <ListItem>
          <Heading as={'h3'} fontSize={'2xl'}>
            Links
          </Heading>
        </ListItem>
        <Text>
          DataVids has not checked on the majority of the websites or links
          connected to its website and is not in charge of the substance of any
          such connected webpage. The incorporation of any connection does not
          infer support by DataVids of the site. Utilization of any such
          connected site is at the user&#x27;s own risk.
        </Text>
        <ListItem>
          <Heading as={'h3'} fontSize={'2xl'}>
            Site Terms of Use Modifications
          </Heading>
        </ListItem>
        <Text>
          DataVids may update these terms of utilization for its website
          whenever without notification. By utilizing this site you are
          consenting to be bound by the then current form of these Terms and
          Conditions of Use.
        </Text>
        <ListItem>
          <Heading as={'h3'} fontSize={'2xl'}>
            Governing Law
          </Heading>
        </ListItem>
        <Text>
          Any case identifying with DataVids&#x27;s site should be administered
          by the laws of the country of Israel DataVids State without respect to
          its contention of law provisions.
        </Text>
        <Text>
          General Terms and Conditions applicable to Use of a Web Site.
        </Text>
      </OrderedList>
    </Box>
  );
}
