import { useRef, useState } from 'react';
import {
  Stack,
  FormControl,
  Input,
  Button,
  useColorModeValue,
  Heading,
  Text,
  Container,
  Flex,
  Box,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { Widget } from '@typeform/embed-react';
import { Element } from 'react-scroll';

export default function SignUp() {
  const [email, setEmail] = useState('');
  const [state, setState] = useState('initial');
  const [error, setError] = useState(false);
  const signUpRef = useRef(null);
  const handleSubmit = async () => {
    if (!email) {
      setError(true);
      return;
    }
    setState('submitting');
    const response = await fetch(
      `https://api.buildable.dev/flow/v1/call/live/complete-form-4843b62698`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      }
    );
    const data = await response.json();
    if (data.error) {
      setState('error');
      return;
    }
    console.log(data);
    setState('success');
  };
  return (
    <Element name="signUpSection">
      <Flex
        ref={signUpRef}
        minH={{ base: 'auto', md: '450px' }}
        align={'center'}
        justify={'center'}
        mt={'40px'}
        mb={'40px'}
        bg={useColorModeValue('#fff', 'gray.800')}
      >
        <Container
          maxW={'2xl'}
          bg={useColorModeValue('white', 'whiteAlpha.100')}
          rounded={'lg'}
          p={4}
          direction={'column'}
        >
          <Heading
            as={'h2'}
            fontSize={{ base: '25px', sm: '5xl' }}
            textAlign={'center'}
            mb={5}
          >
            Signup for early access ⚡️
          </Heading>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            as={'form'}
            spacing={'12px'}
            onSubmit={e => {
              e.preventDefault();
              setError(false);
              setState('submitting');
              handleSubmit();
            }}
          >
            <FormControl>
              <Input
                variant={'solid'}
                borderWidth={1}
                color={'gray.800'}
                _placeholder={{
                  color: 'gray.400',
                }}
                borderColor={useColorModeValue('gray.300', 'gray.700')}
                id={'email'}
                type={'email'}
                required
                placeholder={'Your Email'}
                aria-label={'Your Email'}
                value={email}
                disabled={state !== 'initial'}
                onChange={e => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl w={{ base: '100%', md: '40%' }}>
              <Button
                color={'#fff'}
                backgroundColor={state === 'success' ? 'green' : '#000'}
                isLoading={state === 'submitting'}
                w="100%"
                type={state === 'success' ? 'button' : 'submit'}
              >
                {state === 'success' ? <CheckIcon /> : 'Submit'}
              </Button>
            </FormControl>
          </Stack>
          <Text
            mt={2}
            textAlign={'center'}
            color={error ? 'red.500' : 'gray.500'}
          >
            {error
              ? 'Oh no an error occured! 😢 Please try again later.'
              : "You won't receive any spam! ✌️"}
          </Text>
        </Container>
      </Flex>
      {state === 'success' && (
        <Box
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            bottom: 0,
            right: 0,
            zIndex: 2,
          }}
        >
          <Widget
            id="sGZZBJf3"
            style={{ width: '100%', height: '100%' }}
            className="my-form"
          />
        </Box>
      )}
    </Element>
  );
}
