import React from 'react';
import {
  Stack,
  Container,
  ChakraProvider,
  extendTheme,
  Link,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import Hero from './components/hero';
import CreativeExample from './components/creative-example';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';
import Cta from './components/cta';
import Intergrations from './components/intergrations';
import SignUp from './components/signUp';
import Footer from './components/footer';
import { Routes, Route } from 'react-router-dom';
import Privacy from './views/Privacy';
import Terms from './views/Terms';
import { Logo } from './components/Logo';
import { Link as ReachLink } from 'react-router-dom';
import ScrollToTop from './hooks/useScrollToTop';
const fonts = {
  heading: `'Poppins', sans-serif`,
  body: `'Poppins', sans-serif`,
};
const colors = {
  brand: {
    900: 'black',
    800: '#153e75',
    700: '#2a69ac',
  },
};
const theme = extendTheme({ colors, fonts });

const Home = () => {
  return (
    <>
      <Hero />
      <Cta />
      <CreativeExample />
      <Intergrations />
      <SignUp />
    </>
  );
};
function App() {
  return (
    <ChakraProvider theme={theme}>
      <Container
        as={Stack}
        px={0}
        maxW={'5xl'}
        paddingTop={'10px'}
        direction={'row'}
        justify={{ base: 'space-between', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Link as={ReachLink} to="/">
          <Logo />
        </Link>
        <ColorModeSwitcher justifySelf="flex-end" />
      </Container>

      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
      </Routes>
      <Footer />
    </ChakraProvider>
  );
}

export default App;
